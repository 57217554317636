import { Grid, Link, Typography } from '@material-ui/core';
import React from 'react';

import Box from '../../../components/Box/Box';
import ServiceTile from '../../../components/ServiceTile/ServiceTile';
import Accordion from './../../../components/Accordion/Accordion';

export const Faq = {
  title: 'FAQ',
  content: (
    <div>
      <Grid
        container
        spacing={2}
        className="direction-xs-column-reverse direction-lg-row"
      >
        <Grid item lg={9}>
          <Box>
            <h2>FAQ - Häufig gestellte Fragen</h2>
            <div className="video-wrapper">
              <video
                controls={true}
                preload="none"
                poster="https://www.diebayerische.de/dam/jcr:5e7b310a-e29a-443f-a952-8305934d6152/plusrente%20video.JPG"
              >
                <source
                  src="https://data.diebayerische-boam.is2.cloud/api/public/dl/rRGhummM/bayerische-plusrente-de_v2.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <Typography
              id="allgemein"
              variant="h2"
              color="primary"
              style={{ marginTop: '30px', marginBottom: '10px' }}
            >
              Allgemein
            </Typography>
            <Accordion
              items={[
                {
                  title: 'Wie profitiere ich von den Vorteilen der plusrente?',
                  content:
                    'Nach jedem Einkauf über plusrente.de erhalten Sie Cashback welches über den Bruttowarenwert berechnet wird und entweder als Fixbetrag oder als prozentualer Anteil auf der jeweiligen Shopdetailseite ausgewiesen ist. Bei der plusrente gibt es keine Bonuspunkte, die Sie lange und aufwändig sammeln und dann gegen Prämien eintauschen müssen. Bei uns erhalten Sie ganz bequem und sicher für jeden Einkauf den von uns mit dem Onlineshop ausgehandelten Rabatt auf Ihre plusrente Altersvorsorge gutgeschrieben.',
                },
                {
                  title:
                    'Ist der Service von plusrente.de für mich wirklich absolut kostenlos?',
                  content:
                    'Ja. Plusrente.de ist eine zusätzliche, kostenfreie Dienstleistung exklusiv für die Kunden, die einen Altersvorsorgevertrag „die plusrente“ beim Versicherer „die Bayerische“ abgeschlossen haben.',
                },
              ]}
            ></Accordion>
            <Typography
              id="registrierung"
              variant="h2"
              color="primary"
              style={{ marginTop: '30px', marginBottom: '10px' }}
            >
              Registrierung
            </Typography>
            <Accordion
              items={[
                {
                  title: 'Wie registriere ich mich?',
                  content:
                    '<div>Als Kunde der plusrente registrieren Sie sich das erste Mal mit Ihrem Namen und Ihrer E-Mail-Adresse. Danach erhalten Sie einen Bestätigungscode per E-Mail. Nach Eingabe des Bestätigungscodes müssen Sie nur noch die Versicherungsscheinnummer und Ihr Geburtsdatum eingeben und schon ist die Registrierung abgeschlossen. Diese Zugangsdaten können Sie auch für den Kunden- und Vertragsmanager “Meine Bayerische” nutzen. <br /> <br /> <b>Hinweis:</b> Wenn Sie bereits einen Zugang zu dem Kunden- und Vertragsmanager “Meine Bayerische” haben, benötigen Sie keine separate Registrierung auf <a href="https://plusrente.de">plusrente.de</a>. Sie können hier wie gewohnt Ihre Zugangsdaten von dem Kunden- und Vertragsmanager “Meine Bayerische” verwenden. </div>',
                },
                {
                  title:
                    'Meine Registrierung funktioniert nicht – Wir helfen Ihnen!',
                  content:
                    'Wenn Sie sich nicht registrieren können, kontaktieren Sie bitte unsere Service Hotline unter 089/6787-3939 oder per Mail unter uhd@diebayerische.de',
                },
              ]}
            ></Accordion>
            <Typography
              id="anmeldung"
              variant="h2"
              color="primary"
              style={{ marginTop: '30px', marginBottom: '10px' }}
            >
              Anmeldung
            </Typography>
            <Accordion
              items={[
                {
                  title: 'Wie logge ich mich ein?',
                  content:
                    '<div>Um sich nach der erfolgreichen Registrierung auf <a href="https://plusrente.de">plusrente.de</a> anzumelden, verwenden Sie bitte Ihre E-Mail-Adresse, sowie Ihr bereits definiertes Passwort. <br /><br /> <b>Hinweis:</b> Wenn Sie bereits einen Zugang zu dem Kunden- und Vertragsmanager “Meine Bayerische” haben, sind die Login-Daten auf <a href="https://plusrente.de">plusrente.de</a> dieselben.  </div>',
                },
                {
                  title: 'Wie kann ich meine Zugangsdaten ändern?',
                  content:
                    'Um Ihre Zugangsdaten (E-Mail-Adresse und Passwort) ändern zu können, besuchen Sie bitte das Plusrente Portal und klicken Sie auf „Meine Plusrente“ dort können Sie unter „Meine Einstellungen“ über den dort angezeigten Link, Ihre Zugangsdaten ändern.',
                },
                {
                  title:
                    'Ändern sich die Preise und Bedingungen der Partnershops bei der Nutzung des plusrente Cashbacks?',
                  content:
                    'Wenn Sie über den Bereich Cashback einkaufen, gelten dieselben Bedingungen, die Sie auch direkt bei einem Shop erhalten, d. h. Sie genießen die gleichen Garantien sowie den gleichen Kunden- und Lieferservice. Mit Cashback profitieren Sie aber von speziellen Vorteilen und können automatisch Geld sparen. Falls Sie bereits Kunde eines Partnershops sind, können Sie Ihre bestehenden Kundenzugangsdaten weiterhin nutzen und müssen lediglich über den Bereich Cashback zum Partnershop klicken. Einzige Ausnahme in diesem Fall ist die Nutzung von Gutscheinen. Bitte lösen Sie nur Gutscheine in den jeweiligen Partnershops ein, wenn es sich um Gutscheine eines Shops handelt, die auch in dem Bereich Gutschein & Angebote angeboten werden. Bei der Nutzung so genannter „Fremdgutscheine“ oder weiterer Provisionen bzw. Rabatte kann kein Cashback zusätzlich ausgeschüttet werden.',
                },
                {
                  title: 'Wie wird mein Cashback berechnet?',
                  content:
                    'Ihr Cashback wird über den Nettowarenwert berechnet und kann entweder als prozentualer Anteil oder als Fixbetrag (diese Informationen erhalten Sie auf der Shopdetail-Anzeige im Portal) ausgewiesen sein. Der Nettowarenwert ist die Einkaufssumme ohne der Mehrwertsteuer und ohne Berücksichtigung der Versandkosten oder ähnlichen Gebühren. Wenn Sie beispielsweise eine DVD für 20,- Euro kaufen und mit Versandkosten 23,90 Euro bezahlen müssen, dann beträgt der Nettowarenwert abzüglich der Versandkosten und der MwSt. von 19 % insgesamt bei 16,81 Euro. Auf diesen Betrag gibt es dann das Cashback. Bitte beachten Sie, dass die MwSt. mal 19 % und mal 7 % betragen kann.',
                },
                {
                  title:
                    'Ich habe über das plusrente Portal eingekauft – wie geht es nun weiter?',
                  content:
                    'Nachdem Sie Ihre Einkäufe getätigt haben, bekommen Sie Ihre Ware vom jeweiligen Shop geliefert. Nach kurzer Zeit wird Ihnen der Einkauf dort automatisch angezeigt. In Ihrem Kundenkonto können Sie unter „plusrente Guthaben“ jederzeit Ihren Bestell- und Cashbackstatus einsehen und haben somit immer einen genauen Überblick über die erfassten Einkäufe. Die Abwicklung des Kaufes erfolgt wie gewohnt zwischen Ihnen und dem jeweiligen Shop, d. h. den vollen Kaufpreis zahlen Sie an die Shop-Partner direkt.',
                },
                {
                  title:
                    'Wie benutze ich den Service Cashback im plusrente Portal?',
                  content:
                    'Sie loggen sich im Plusrente Portal ein, kaufen bei einem Partnershop einen oder mehrere Artikel oder schließen über einen Partnershop einen Vertrag ab. Dieser Einkauf bzw. Vertragsabschluss bei dem Partnershop wird automatisch registriert und Ihr Cashback innerhalb von 24 Stunden im Plusrente Portal unter „plusrente Guthaben“ vorgemerkt. Nach Ablauf der üblichen Widerrufsfristen wird das Cashback als bestätigt gebucht und ab einem Mindestguthaben von 50 EUR automatisch auf Ihren Altersvorsorgevertrag gutgeschrieben. Den aktuellen Status Ihrer Transaktionen können Sie übrigens jederzeit unter „plusrente Guthaben“ einsehen.',
                },
                {
                  title:
                    'Trotz Einkauf wurde mir kein Cashback gutgeschrieben – woran kann das liegen?',
                  content:
                    'Es kann vorkommen, dass Ihr Einkauf nicht korrekt „technisch markiert“ worden ist, weil bspw. der Cookie, der für die Zuordnung des Einkaufs verantwortlich ist, nicht fehlerfrei gesetzt werden konnte. Sollten Sie Ihr Cashback nicht in Ihrer Transaktions-Auflistung finden, warten Sie zunächst ca. 24 Stunden ab, ob es sich nur um eine Verzögerung handelt. Sollte auch nach 24 Stunden keine Transaktion vorgemerkt sein, können Sie eine Nachbuchungsanfrage stellen.',
                },
                {
                  title:
                    'Mein Cashback wurde nachträglich storniert – woran liegt das?',
                  content:
                    '<div>Nach Ablauf der üblichen Widerrufsfristen wird das Cashback als bestätigt gebucht. Cashbackzahlungen können natürlich nur geleistet werden, wenn auch tatsächlich eine Transaktion bzw. Anmeldung registriert worden ist und final stattgefunden hat. Folgende Gründe für eine Stornierung Ihres Cashbacks sind möglich: <ul><li>Sie haben eine Bestellung wieder zurückgeschickt.</li><li>Ihre Kreditkarte ist nicht gedeckt bzw. der bestellte Artikel wurde nicht von Ihnen bezahlt.</li><li>Die Transaktion wurde von dem Partner storniert (Artikel nicht verfügbar o. ä.).</li><li>Es wurde ein Gutschein verwendet, der nicht für das Cashback-Programm zugelassen war.</li><li>Bitte beachten Sie auch, dass bei Teilstornierungen der Bestellungen meistens das komplette Cashback storniert wird.</li></ul></div>',
                },
                {
                  title:
                    'Wann bekomme ich mein Cashback gutgeschrieben und wo wird mir dies angezeigt?',
                  content:
                    'Sobald Sie einen Einkauf getätigt haben und wir vom Partnershop eine Meldung über Ihre Transaktion erhalten haben, wird Ihnen Ihr Cashback angezeigt. Die Anzeige der Cashback-Gutschrift hängt von der Bearbeitungszeit der Partnershops ab. Das Ganze kann einige Tage in Anspruch nehmen. Sie finden Ihr gesammeltes Guthaben unter der Funktion „plusrente Guthaben“, sodass Sie sich jederzeit über den aktuellen Stand informieren können. Bitte haben Sie Verständnis, dass wir keinen Einfluss darauf haben, wie lange ein Partnershop benötigt, um einen Einkauf zu überprüfen. Die durchschnittliche Bearbeitungsdauer durch die Partnershops beträgt 12-16 Wochen. Bei Reise- und Hotelbuchungen, sowie Flug- und Mietwagenbuchungen beginnt dieser Zeitraum erst mit Antritt der Reise/ Beginn des Mietzeitraums.',
                },
                {
                  title:
                    'Warum wird mir ein abweichender Einkaufs- oder Cashbackbetrag ausgewiesen?',
                  content:
                    'Es kann unter Umständen vorkommen, dass Ihnen ein geringerer Betrag als erwartet gutgeschrieben wird. Ein Grund könnte sein, dass Sie einen Teil der Bestellung retourniert haben. Ein weiterer Grund kann darin liegen, dass die Partnershops den Einkaufswert nicht richtig übermitteln. Sollte der Einkaufswert zu gering sein, können Sie uns dies über das Kontaktformular mitteilen und wir kümmern uns um eine Korrektur.',
                },
                {
                  title: 'Wieviel Cashback kann ich maximal erzielen?',
                  content:
                    'Sie erhalten bei jedem Einkauf über die plusrente einen Teil Ihrer Einkaufssumme bzw. beim Einsatz Ihrer pluskarte 1 % vom Umsatz erstattet. Die Höhe des Cashbacks pro getätigtem Onlineeinkauf hängt immer vom jeweiligen Onlineshop ab bei dem Sie einkaufen. Entweder vergibt der Onlineshop fixe Prämien oder Rückerstattungen, die an den Nettopreis Ihrer Bestellung gekoppelt sind. Bei unseren Partnern sind Einsparungen bis zu 20 Prozent möglich.',
                },
                {
                  title:
                    'Sind die Preise und Bedingungen bei den Partnershops die gleichen wie bei einem normalen Einkauf welcher nicht über plusrente.de erfolgt?',
                  content:
                    'Wenn Sie über plusrente.de shoppen, gelten dieselben Bedingungen wie bei einem Einkauf direkt über den Onlineshop. Das heißt, Sie genießen die gleichen Garantien sowie den Kunden- und Lieferservice welchen Sie sonst auch vom jeweiligen Shop erhalten würden. Mit der plusrente profitieren Sie jedoch zusätzlich von speziellen Cashback Vorteilen und können somit günstiger einkaufen. Falls Sie bereits Kunde eines Partnershops sind, können Sie Ihre bestehenden Kundendaten weiterhin nutzen und müssen sich lediglich über plusrente.de zum Partnershop weiterleiten lassen. Bitte achten Sie darauf, dass Sie lediglich Gutscheine verwenden, welche auf plusrente.de angeboten werden. Alle anderen Fremdgutscheine, die Sie zusätzlich zu Ihrem Cashback Shopping einsetzen, führen automatisch dazu, dass das Cashback abgelehnt wird.',
                },
              ]}
            ></Accordion>
            <Typography
              id="technik"
              variant="h2"
              color="primary"
              style={{ marginTop: '30px', marginBottom: '10px' }}
            >
              Technik
            </Typography>
            <Accordion
              items={[
                {
                  title:
                    'Was kann verhindern, dass mein Cashback erfasst wird?',
                  content:
                    '<div>Es gibt einige Faktoren, die die Zuordnung Ihrer Daten erschweren bzw. unmöglich machen. Daher möchten wir Ihnen folgende Empfehlungen für Ihren Einkauf über das Plusrente Portal geben:<ul><li>Ihr Browser muss Cookies (insbesondere sogenannte „Drittanbieter“ oder „3rd-Party“ Cookies) akzeptieren und JavaScript sollte aktiviert sein. Lesen Sie dazu bei Bedarf die Hilfe Ihres Browsers.</li><li>Deaktivieren Sie eventuelle Ad-Blocker oder Pop-Up-Blocker. Auch Kindersicherungsprogramme können die Erfassung des Cashbacks stören.</li><li>Melden Sie sich dann im Plusrente Portal an und rufen Sie von dort aus den Shop Ihrer Wahl auf.</li><li>Öffnen Sie nun keine weiteren Tabs oder Fenster mehr und klicken Sie auch nichts in anderen Tabs oder Fenstern an. Rufen Sie von nun an bitte insbesondere keine Preisvergleiche oder Gutscheinseiten mehr auf.</li><li>Bitte führen Sie Ihren Kaufprozess in einem Durchgang und ohne Unterbrechungen durch.</li></ul>Grundbedingung für eine richtige Zuweisung ist, dass Sie sich über das plusrente Portal zu unserem Partnershop weiterleiten lassen – falls Sie direkt auf die Partnerseite gehen oder die Bestellung telefonisch beim Partner aufgeben, können wir Ihre Daten nicht zuordnen und Sie erhalten kein Cashback. Daher ist es wichtig, dass Sie sich zuerst einloggen und dann auf die Seite des Partnershops gehen.</div>',
                },
                {
                  title: 'Was sind Cookies und warum sind diese wichtig?',
                  content:
                    'Cookies sind kleine Textdateien, die in Ihrem Browser gespeichert werden. Es wird – neben grundsätzlichen Informationen über das Plusrente Portal und den Partnershops – lediglich eine eindeutige Kennung darin gespeichert, die die Transaktionen Ihres Kontos zuordnet. Diese Kennung ist für den Partnershop nicht verwertbar. Persönliche Informationen werden auf keinen Fall darin gespeichert. Für die Erfassung des Einkaufs und für das Cashback ist das Cookie allerdings sehr wichtig, da nur so der Partnershop zuordnen kann über welche Website der Einkauf vermittelt wurde und daher das Cashback gezahlt werden muss.',
                },
                {
                  title: 'Muss ich JavaScript aktivieren?',
                  content:
                    'JavaScript wird von einigen Shops zur Zuordnung eingesetzt. Ebenso ist JavaScript für eine fehlerfreie Darstellung der Seite unverzichtbar. Sollte das plusrente Portal nicht richtig funktionieren oder angezeigt werden, prüfen Sie bitte, ob die Funktion aktiviert ist.',
                },
                {
                  title:
                    'Was passiert, wenn in meinem Browser schon Zuordnungscookies gespeichert sind?',
                  content:
                    'Viele Websites, insbesondere solche mit viel Werbung, setzen Cookies zur Marketingsteuerung und Generierung von Werbeeinnahmen ein. Wenn in Ihrem Browser schon Zuordnungscookies gespeichert sind, kann das zu Problemen führen. Um den Fehler von vornherein ausschließen zu können, löschen Sie bitte alle Cookies, bevor Sie mit dem Einkauf über das plusrente Portal starten.',
                },
                {
                  title:
                    'Was verbirgt sich hinter der Information "Vorgemerkt nach"?',
                  content:
                    'Nachdem Sie bei einem der plusrente Partnershops eingekauft haben dauert es eine gewisse Zeit bis Ihr Einkauf in Ihrem Konto mit dem Status „Vorgemerkt“ angezeigt wird. Erst nachdem der Partnershop eine Rückmeldung gesendet hat kann Ihre Bestellung mit den richtigen Daten angezeigt werden. In der Statistik welche Sie auf den jeweiligen Shopdetailseiten finden erhalten Sie Informationen darüber, wie lange es ungefähr dauert, bis Ihre Bestellung beim entsprechenden Händler vorgemerkt wird. Kurzum: Bei diesem Wert handelt es sich um den Zeitraum zwischen dem Zeitpunkt Ihres Einkaufs und dem Zeitpunkt, zu dem Ihr Einkauf im System erfasst wurde.',
                },
                {
                  title:
                    'Was verbirgt sich hinter der Information "Bestätigt nach"?',
                  content:
                    'Wird Ihre Bestellung beim jeweiligen Händler auf seine Validität überprüft. Der Partnershop stellt sicher, dass Ihre Bestellung beispielsweise nicht zurückgesendet oder annulliert wird. Diese Prüfdauer ist von Partnershop zu Partnershop unterschiedlich lang und nimmt eine gewisse Zeit in Anspruch. Mit der Bezeichnung „Bestätigt nach“ wird Ihnen der Zeitraum zwischen dem Registrierungsdatum des Einkaufs und der Bestätigung durch den Partnershop angezeigt.',
                },
                {
                  title:
                    'Was verbirgt sich hinter der Information "Auszahlung nach"?',
                  content:
                    'Wurde Ihr Einkauf erfolgreich bestätigt warten wir auf den Zahlungseingang des jeweiligen Onlineshops. Sobald das Cashback bei uns eingetroffen ist, wird dieser Bonus bei Ihnen im Konto mit dem dem Status „auszahlfähig“ angezeigt. Da die Auszahlung automatisch erfolgt, wenn Sie mindestens 50 EUR Cashback gesammelt haben, handelt es sich hier um einen Durchschnittswert.',
                },
                {
                  title:
                    'Was verbirgt sich hinter der Information "Auszahlung nach"?',
                  content:
                    'Wurde Ihr Einkauf erfolgreich bestätigt warten wir auf den Zahlungseingang des jeweiligen Onlineshops. Sobald das Cashback bei uns eingetroffen ist, wird dieser Bonus bei Ihnen im Konto mit dem dem Status „auszahlfähig“ angezeigt. Da die Auszahlung automatisch erfolgt, wenn Sie mindestens 50 EUR Cashback gesammelt haben, handelt es sich hier um einen Durchschnittswert.',
                },
                {
                  title:
                    'Was ist, wenn ich Kindersicherungsprogramme auf meinem Computer installiert habe?',
                  content:
                    'Kindersicherungsprogramme hindern Ihren Browser an der Darstellung bestimmter gesperrter Inhalte. Diese Programme können auch die Funktion von plusrente.de stören. Um einen einwandfreien Zugang zu gewährleisten, deaktivieren Sie bitte diese Programme während Sie über plusrente.de shoppen.',
                },
                {
                  title:
                    'Kann ein Sicherheitsprogramm auf meinem Computer Werbung oder Cookies blockieren?',
                  content:
                    'Sicherheitsprogramme wie zum Beispiel Norton Internet Security, Kaspersky Internet Security, Gdata Internet Security usw. können ebenso Cookies, JavaScript und Werbung blockieren. Prüfen Sie bitte in Ihrem Sicherheitsprogramm, ob solche Einstellungen aktiviert sind und deaktivieren Sie diese gegebenenfalls für den Zeitraum der Bestellung. Andernfalls kann eine einwandfreie Funktion der Zuordnung nicht gewährleistet werden.',
                },
                {
                  title:
                    'Ist die Ablehnung von Cookies und JavaScript in meinem Browser aktiviert?',
                  content:
                    'Cookies sind kleine Textdateien, die in Ihrem Browser gespeichert werden. Es wird aber nur eine eindeutige Kennung darin gespeichert, die Ihre Transaktionen Ihrem Konto zuordnen. Persönliche Informationen werden auf keinen Fall darin gespeichert.',
                },
                {
                  title: 'Was ist, wenn ich einen Pop-up-Blocker benutze?',
                  content:
                    'Auch Pop-Up-Blocker können die Funktionen von plusrente.de stören. Deaktivieren Sie bitte die Blocker während Ihres Online-Einkaufs oder fügen Sie die Webseite plusrente.de zu den sicheren Seiten im Browser hinzu.',
                },
              ]}
            ></Accordion>
            <Typography
              id="gutscheine"
              variant="h2"
              color="primary"
              style={{ marginTop: '30px', marginBottom: '10px' }}
            >
              Gutscheine
            </Typography>
            <Accordion
              items={[
                {
                  title: 'Wie kann ich Gutscheine nutzen?',
                  content:
                    'Gutscheine nutzen Sie, indem Sie in der Gutscheinübersicht auf den Button "zum Shop" klicken und den angezeigten Gutscheincode kopieren. Während des Bestellvorgangs im Onlineshop fügen Sie diesen Code dann einfach in das dafür vorgesehene Feld direkt im Partnershop ein. Bitte achten Sie darauf, dass Sie lediglich Gutscheine verwenden, welche auf plusrente.de angeboten werden. Alle anderen Fremdgutscheine, die Sie zusätzlich zu Ihrem Cashback shopping einsetzen, führen automatisch dazu, dass das Cashback abgelehnt wird. Die Onlineshop Gutscheine auf plusrente.de sind für jeden frei verfügbar und erfordern keine plusrente.',
                },
              ]}
            ></Accordion>
            <Typography
              id="auszahlung"
              variant="h2"
              color="primary"
              style={{ marginTop: '30px', marginBottom: '10px' }}
            >
              Auszahlung
            </Typography>
            <Accordion
              items={[
                {
                  title:
                    'Wie und wann wird mir das gesammelte Cashback ausgezahlt?',
                  content:
                    'Nachdem das Cashback bestätigt wurde, wird die entsprechende Summe nach Erreichen eines Mindestguthabens von 50 EUR, regelmäßig auf Ihren Altersvorsorgevertrag gutgeschrieben. Eine Barauszahlung des gesammelten Cashbacks ist nicht möglich.',
                },
              ]}
            ></Accordion>
            <Typography
              id="reiseservice"
              variant="h2"
              color="primary"
              style={{ marginTop: '30px', marginBottom: '10px' }}
            >
              Reiseservice:
            </Typography>
            <Accordion
                items={[
                    {
                        title: 'Wie kann ich eine Reise buchen?',
                        content:
                            'Sie können den Reiseservice telefonisch unter der 089 6787 5075 oder online im plusrente Portal in Anspruch nehmen.',
                    },
                    {
                        title: 'Wann erfolgt die Auszahlung der Reiserückvergütung?',
                        content:
                            'Die Gutschrift der Reiserückvergütung erfolgt im Folgemonat nach Reiserückkehr. Nachdem die Reiserückvergütung bestätigt wurde, wird die entsprechende Summe nach Erreichen eines Mindestguthabens von 50 EUR, regelmäßig auf Ihren Altersvorsorgevertrag gutgeschrieben. Eine Barauszahlung der gesammelten Reiserückvergütung ist nicht möglich.',
                    },
                    {
                        title:
                            'Auf welchen Service/Reise erhalte ich keine Reiserückvergütung?',
                        content:
                            'Ausgenommen sind Steuern und Gebühren, Tourismusabgaben, Servicepauschalen, Um- und Zubuchungen vor Ort, Treibstoff- und Kerosinzuschläge, Übergepäck, Sitzplatzreservierungen, Zustellbetten, E-Bikes bei Fahrradreisen, Zuschläge Business-Upgrade in Pauschalreisen einzeln gebuchte Versicherungen, An- und Abreisepakete sowie Ausflugs- und Getränkepakete bei Kreuzfahrten, Bahn-, Flug- und Fährtickets, einzeln gebuchte Transferleistungen, persönliche Ausgaben (Verpflegung, Pay-TV, Parkgebühren, Minibar, u. dgl.), stornierte Reisen, Reisen des Veranstalters AIDA Cruises, Reisen, bei denen der Reiseveranstalter nach Buchung insolvent gegangen ist, ggf. erhobene Kreditkartengebühren.',
                    },
                    {
                        title:
                            'Ich habe Fragen zu meiner Buchung. An wen kann ich mich wenden?',
                        content:
                            'Bei Fragen zu Ihrer Buchung, kontaktieren Sie das plusrente ServiceCenter unter 089 6787 5075.',
                    },
                    {
                        title:
                            'Wird mir die Reiserückvergütung auch dann ausgezahlt, wenn ich für Freunde/Bekannte buche und selbst nicht mitreise?',
                        content:
                            'Nein, das ist nicht möglich! Allerdings gibt es die Möglichkeit, dass Freunde/Bekannte auf Ihre Altersvorsorge sparen können. Dafür müssen sie sich im Portal als Ihr/e Freund/in oder Bekannte/r registrieren und selbst eine Reise buchen. Die gesammelte Rückvergütung wird dann wird dann in Ihre Altersvorsorge eingezahlt.',
                    },
                ]}
            ></Accordion>
            <Typography
              id="vorort"
              variant="h2"
              color="primary"
              style={{ marginTop: '30px', marginBottom: '10px' }}
            >
              Vor-Ort Angebote:
            </Typography>
            <Accordion
              items={[
                {
                  title: 'Wie lange ist ein vor Ort Angebot gültig?',
                  content:
                    'So lange Sie die Angebote online sehen können, sind diese auch gültig. Online können Sie vorher schon einsehen, wenn das Angebot eine zeitliche Begrenzung besitzt.',
                },
                {
                  title: 'Kann ich Shops vorschlagen?',
                  content:
                    'Sehr gerne sogar. Wenn Ihnen ein Shop gefällt, den Sie vorschlagen möchten, können Sie uns diesen Vorschlag jederzeit per Mail, Post oder Fax schicken oder Sie füllen online das Kontaktformular aus.',
                },
                {
                  title:
                    'Welche Voraussetzungen muss ein Shop erfüllen, um aufgenommen zu werden?',
                  content:
                    'Der Shop muss dem Kunden einen vor Ort Rabatt für den Einkauf gewähren und diesen zusätzlich beim Einkauf in Abzug bringen können.',
                },
                {
                  title:
                    'Kann man das Angebot auch mit Rabattaktionen oder Coupons kombinieren?',
                  content:
                    'Nein, die vor Ort Angebote sind nicht mit anderen Aktionen kombinierbar.',
                },
                {
                  title:
                    'Gilt das vor Ort Angebot nur vor Ort oder auch für den Online Shop der Filiale?',
                  content:
                    'Dieses Angebot gilt meistens nur vor Ort. Es kann natürlich sein, dass der ein oder andere Shop parallel noch online einen Rabattcode hat, den Sie nutzen können.',
                },
                {
                  title: 'Gilt das Angebot in jeder Filiale des Shops?',
                  content:
                    'Das Angebot gilt nur in den Filialen, die im vor Ort Angebot explizit erwähnt sind.',
                },
              ]}
            ></Accordion>

            <Typography
              id="cashback"
              variant="h2"
              color="primary"
              style={{ marginTop: '30px', marginBottom: '10px' }}
            >
              Cashback-Radar:
            </Typography>

            <Accordion
              items={[
                {
                  title: 'Was ist das plusrente Cashback-Radar?',
                  content:
                    'Das plusrente Cashback-Radar ist eine kostenlose, dezente und einfache Software, mit der Sie jeden plusrente Onlineshop sofort erkennen und somit keine Möglichkeit auf cashback mehr verpassen. Mit dem plusrente Cashback-Radar ist es nicht mehr notwendig, die Seite plusrente.de vor einem Einkauf zu besuchen um cashback zu erhalten. Bei dem Cashback-Radar handelt es sich um ein Browser Plug-In, welches im Vorfeld heruntergeladen und installiert werden muss. Aktuell ist das plusrente Cashback-Radar für Firefox und Chrome erhältlich.',
                },
                {
                  title: 'Wie funktioniert das plusrente Cashback-Radar?',
                  content:
                    'Nachdem Sie das plusrente Cashback-Radar installiert haben erscheint in Ihrem Browser oben links das plusrente Icon. Durch einen „Klick“ darauf finden Sie eine in Kategorien unterteilte Übersicht aller plusrente Partnershops, sowie die zughörigen cashback Raten. Das Cashback-Radar meldet sich automatisch sobald Sie bei einem Partnershop einkaufen wollen und die cashback Funktion noch nicht aktiviert haben. Jetzt müssen Sie sich nur noch in das Cashback-Radar einloggen und können so cashback verdienen, ohne den Umweg über plusrente.de zu machen.',
                },
                {
                  title:
                    'Warum wird bei einigen Partnershops das plusrente Cashback-Radar nicht angezeigt?',
                  content:
                    'Das plusrente Cashback-Radar funktioniert nicht bei allen Partnershops. Dies liegt daran, dass nicht alle Onlineshops ein solches Plug-In wünschen. In diesen Fällen werden die Bedingungen der einzelnen Partnershops beachtet, so dass Ihnen die Menüleiste hier nicht angezeigt wird.',
                },
                {
                  title: 'Wie melde ich mich in der Extension an?',
                  content: `<ul>
                      <li>
                        <strong>Option 1:</strong>
                        <p>Die Extension wird sofort angezeigt, sobald Sie in unseren
                        Partnershops shoppen. Klicken Sie auf die orangene Schaltfläche am
                        oberen rechten Rand Ihres Browsers.</p>
                      </li>
                      <li>
                        <strong>Option 2:</strong>
                        <p>Klicken Sie auf den Plusrente Cashback Radar am oberen rechten
                        Rand Ihres Browsers. Betätigen Sie nun den Anmelden-Button und Sie
                        werden auf die Anmeldeseite des plusrente Portals weitergeleitet.
                        Anschließend öffnet sich in einem neuen Reiter Ihres Browsers der
                        Login für das plusrente Portal. Mit Ihren gewohnten Zugangsdaten
                        loggen Sie sich nun ein.
                        <br />
                        Durch den Login im Portal werden Sie auch im Cashback Radar
                        automatisch eingeloggt.
                        <br />
                        Sie können ab sofort bei unseren Partnershops einkaufen und mit
                        nur einem Klick Ihren Cashback Radar aktivieren. Das Einloggen im
                        Portal entfällt damit zukünftig.</p>
                      </li>
                    </ul>`,
                },
                {
                  title: 'Wie funktioniert die Aktivierung?',
                  content: `<p>Die Aktivierung des Cashbacks wird wie folgt durchgeführt:
                    <br />
                    Kaufen Sie in einem unserer Partnershops ein, wird Ihnen der
                    Cashback Radar angezeigt. Leuchtet die Lampe rot, ist die
                    Extension noch nicht aktiviert. Klicken Sie nun auf die orangene
                    Schaltfläche.
                    <br />
                    Leuchtet die Lampe grün, ist der Cashback Radar aktiviert und das
                    Cashback wird Ihnen automatisch gutgeschrieben.
                    </p>`,
                },
              ]}
            ></Accordion>
          </Box>
        </Grid>

        <Grid item lg={3}>
          <Box style={{ marginBottom: '16px', paddingTop: '41px' }}>
            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Link href="#allgemein">Allgemein</Link>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Link href="#registrierung">Registrierung</Link>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Link href="#anmeldung">Anmeldung</Link>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Link href="#technik">Technik</Link>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Link href="#gutscheine">Gutscheine</Link>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Link href="#pluskarte">Pluskarte</Link>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Link href="#auszahlung">Auszahlung</Link>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Link href="#vorort">Vor-Ort Angebote</Link>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Link href="#reiseservice">Reiseservice</Link>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '16px' }}>
              <Link href="#cashback">Cashback Radar</Link>
            </div>
          </Box>
          <ServiceTile />
        </Grid>
      </Grid>
    </div>
  ),
};
